.logo {
  display: block;
  margin: 0 1.5rem 2rem;
}

.site-nav {
  background: transparent;
  transition: $time;

  &.always-fixed,
  &.is-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: rgba($white, 0.7);
    z-index: 10;
  }

  &.is-opened {
    background: rgba($white, 0.9);
  }
}

.menu {
  margin: 0;
  padding: 0 1.5rem;
  list-style: none;
  text-align: center;

  @media (min-width: $sm + 1) {
    display: block !important;
  }

  @media (max-width: $sm) {
    padding: 0;
    display: none;
    border-top: 1px solid $lightgrey;
  }
}

.menu-item {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  display: inline;

  @media (max-width: $sm) {
    display: block;
    border-bottom: 1px solid $lightgrey;
  }
}

.menu-link {
  display: inline-block;
  padding: 1.5rem 2rem;
  color: $black;
  transition: $time;
  text-decoration: none;

  @media (max-width: $sm) {
    padding: 1.5rem;
    display: block;
  }

  &:hover,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}

.menu-opener {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 1.5rem 2rem;
  color: $black;
  transition: $time;
  display: none;
  height: 5rem;
  text-align: center;
  cursor: pointer;

  @media (max-width: $sm) {
    display: block;
  }
}
