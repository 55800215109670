.site-main {

}

.section {
  position: relative;
  padding: 30rem 0 10rem;

  @media (max-width: $md) {
    padding: 21rem 0 7rem;
  }

  @media (max-width: $sm) {
    padding: 15rem 0 5rem;
  }
}

.section-in {
  padding: 20rem 0 15rem;

  @media (max-width: $md) {
    padding: 15rem 0 10rem;
  }

  @media (max-width: $sm) {
    padding: 10rem 0 7rem;
  }
}

.section-0 {
  padding-top: 8rem;
  padding-bottom: 0;
  background: $white;
}

.section-i {
  background: #eafbf7;
}

.section-ii {
  background: #d1f6dc;
}

.section-iii {
  background: $white;
}

.section-iv {
  background: #dfcdb0;
}

.section-v {
  background: $white;
}

.section-10 {
  display: none;
  padding: 5rem 0 10rem;

  @media (max-width: $sm) {
    padding: 3rem 0 6rem;
  }
}

.slant {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 20rem;
  z-index: 9;

  @media (max-width: $md) {
    height: 15rem;
  }

  @media (max-width: $sm) {
    height: 10rem;
  }

  &.second {
    border-top: 5rem solid #d1f6dc;
    z-index: -1;
  }
}

.project-img {
  margin-bottom: 3rem;

  @media (max-width: $sm) {
    margin-bottom: 1.5rem;
  }
}
