@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}
