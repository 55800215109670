.title {
  font-size: 7.5rem;
  line-height: 1;
  font-family: $ff-primary;

  @media (max-width: $md) {
    font-size: 6rem;
  }

  @media (max-width: $sm) {
    font-size: 5rem;
  }

  @media (max-width: $xs) {
    font-size: 4rem;
  }
}

p,
.small {
  font-size: 2.3rem;
  line-height: 1.7;

  @media (max-width: $md) {
    font-size: 2.1rem;
  }

  @media (max-width: $sm) {
    font-size: 1.9rem;
  }

  @media (max-width: $xs) {
    font-size: 1.7rem;
  }
}

.small {
  margin-bottom: 0;
  font-weight: 700;
}

.smallest {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0.5rem;
  font-weight: 700;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.5rem;
}

h5 {
  font-size: 2.25rem;
}

h6 {
  font-size: 2rem;
}

ul,
ol {
  margin: 0;
  padding: 0 2rem 2rem;
}

li {
  font-size: 2rem;
  line-height: 1.5;

  @media (max-width: $md) {
    font-size: 1.8rem;
  }

  @media (max-width: $sm) {
    font-size: 1.6rem;
  }
}

.section {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1rem 0 0.5rem;
  }

  p {
    font-size: 1.9rem;
    line-height: 1.5;

    @media (max-width: $md) {
      font-size: 1.8rem;
    }

    @media (max-width: $sm) {
      font-size: 1.7rem;
    }
  }

  blockquote {
    margin: 0;
    color: $grey;
  }

  figure {
    margin-bottom: 4rem;

    @media (max-width: $md) {
      margin-bottom: 3rem;
    }

    @media (max-width: $sm) {
      margin-bottom: 2rem;
    }

    @media (max-width: $xs) {
      margin-bottom: 1.5rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
