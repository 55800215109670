html,
body {
  position: relative;
  height: 100%;
}

html {
  font-size: 10px;
  overflow-x: hidden;
}

body {
  font-family: $ff-secondary;
  font-weight: 300;
  color: $text;
  background: transparent;
}

::selection {
  color: $white;
  background: rgba($black, 0.5);
  text-shadow: none;
}

a {
  color: $link;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: $hover;
  }

  &:focus {
    text-decoration: none;
    color: $focus;
  }
}
