$primary: #666;
$secondary: #999;

$text: #333;
$link: $primary;
$hover: $secondary;
$focus: $hover;

$border: #ccc;

$white: #fff;
$black: #000;
$dark: #222;
$grey: #bbb;
$lightgrey: #ddd;
$darkgrey: #888;

$ff-primary: 'Roboto', sans-serif;
$ff-secondary: 'Roboto Slab', serif;
$time: 0.25s;

$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 384px;
