.cc-window {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2000;
    width: 100%;
    max-width: 40rem;
    padding: 2rem;
    background: $lightgrey;
    color: $text;
  
    .cookie-notice {
      //padding: 1rem 0;
    }
    .cc-dismiss {
      //float: right;
      //margin-left: 2rem;
      cursor: pointer;
      display: inline-block;
      text-indent: 0;
      width: auto;
      height: auto;
      border: none;
      background: #d1f6dc;
      border-radius: 0;
      padding: 1rem;
    }
    .cc-link {
      cursor: pointer;
      color: $text;
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    .cc-message {
      display: block;
      margin-bottom: 1rem;
    }
  
    &.cc-invisible {
      display: none;
    }
  }
  