.bricklayer {
  margin: 0 -1.5rem;

  @media (max-width: $lg) {
    margin: 0 -1rem;
  }

  @media (max-width: $sm) {
    margin: 0 -0.75rem;
  }

  @media (max-width: 460px) {
    margin: 0;
  }
}

.bricklayer-column-sizer {
  width: 33.33334% !important;

  @media (max-width: $md) {
    width: 50% !important;
  }

  @media (max-width: 460px) {
    width: 100% !important;
  }
}

.bricklayer-column {
  padding: 0 1.5rem !important;

  @media (max-width: $lg) {
    padding: 0 1rem !important;
  }

  @media (max-width: $sm) {
    padding: 0 0.75rem !important;
  }

  @media (max-width: 460px) {
    padding: 0 !important;
  }
}

.column-item {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%;
  margin-top: 3rem;

  @media (max-width: $lg) {
    margin-top: 2rem;
  }

  @media (max-width: $sm) {
    margin-top: 1.5rem;
  }
}

.column-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: $time;
}

.column-desc {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  font-size: 2.4rem;
  color: $white;
  visibility: hidden;
  opacity: 0;
  transform: translateY(2rem);
  transition: $time;

  @media (max-width: $sm) {
    padding: 1.5rem;
    font-size: 1.8rem;
  }

  @media (max-width: $xs) {
    font-size: 1.8rem;
  }
}

.column-item {
  &:hover {
    .column-overlay {
      background: rgba($black, 0.5);
    }

    .column-desc {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: $sm) {
  .column-overlay {
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
  }

  .column-desc {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
