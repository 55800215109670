.site-footer {
  color: $white;
  background: $black;
  padding: 5rem 0;

  @media (max-width: $sm) {
    padding: 3rem 0;
  }
}

.info {
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: $sm) {
    margin-bottom: 2rem;
  }
}

.info-item {
  font-size: 1.4rem;
  display: inline-block;
  float: left;

  &::after {
    content: '//';
    font-weight: 400;
    padding: 0 0.5rem;
  }

  &:first-child {
    font-weight: 700;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  @media (max-width: $sm) {
    display: block;
    float: none;

    &::after {
      content: none;
    }
  }
}

.impressum {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: right;
  cursor: pointer;
  
  @media (max-width: $sm) {
    text-align: left;
  }
}
