.scroll {
  width: 10rem;
  height: 10rem;
  margin-top: 8rem;
  padding: 1.8rem;
  display: block;
  background: #eafbf7;
  border-radius: 50%;
  transition: $time;
  cursor: pointer;

  &:hover {
    background: #d1f6dc;
  }
}

.button {
  width: 8rem;
  height: 8rem;
  margin-bottom: 1.5rem;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid #d1f6dc;
  border-radius: 50%;
  text-indent: -999rem;
  transition: $time;

  &:hover {
    background-color: #d1f6dc;
    background-position: center center;
    background-repeat: no-repeat;
  }

  @media (max-width: $sm) {
    display: inline-block;
    margin: 0 0.5rem 1rem;
    width: 6rem;
    height: 6rem;
    background-size: 50% 50%;
  }

  &.aleft {
    background-image: url(../img/arrow.svg);
    transform: rotate(90deg);
  }

  &.aright {
    background-image: url(../img/arrow.svg);
    transform: rotate(270deg);
  }

  &.alle {
    background-image: url(../img/alle.svg);
  }

  &.is-inactive {
    opacity: 0.4;
  }

  &.scroll {
    margin-top: 5rem;
    margin-right: auto;
    margin-left: auto;
    background-image: url(../img/arrow.svg);

    @media (max-width: $sm) {
      display: block;
    }
  }
}

.dog {
  position: relative;
  top: -15rem;

  @media (max-width: $lg) {
    top: -13rem;
  }

  @media (max-width: $md) {
    display: none;
  }
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.p0 {
  padding: 0;
}

.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0;
}

.w100 {
  width: 100%;
}
